* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    transition: color 0.5s ease;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


@font-face {
    font-family: Akzidensk;
    src: url(./fonts/AkzidenzGrotesk-Regular.otf) format("");
}

@font-face {
    font-family: Akzidensk Light;
    src: url(./fonts/AkzidenzGrotesk-Light.otf) format("");
}

@font-face {
    font-family: Akzidensk Bold;
    src: url(./fonts/AkzidenzGrotesk-Bold.otf) format("");
}

@font-face {
    font-family: Akzidensk Extra Bold;
    src: url(./fonts/AkzidenzGrotesk-ExtraBold.otf) format("");
}

// VARIABLES ----
// Fonts
$font-primary:'Akzidensk', sans-serif;
$font-h1: 'Akzidensk Bold', sans-serif;
$font-h3: 'Akzidensk', sans-serif;
$font-h4: 'Akzidensk Light', sans-serif;
$font-p: 'Akzidensk Light', sans-serif;
$font-link: 'Akzidensk Bold', sans-serif;

// Colors
$link-dark: cyan;
$link-light: rgb(116, 136, 255);

$bg-dark: #2b3654;
//$bg-dark: #6fff00;
//$bg-dark: rgb(20, 20, 57);
//$bg-light: rgb(218, 211, 201);
$bg-light: rgb(193, 178, 178);

// $primary-dark: #91a3c7;
//primary-dark: #93aee4;
$primary-dark: white;
$primary-light: rgb(55, 37, 37);

$secondary-dark: #f4b570;
//$secondary-dark: cyan;
//$secondary-light: rgb(153, 121, 123);
$secondary-light: darkslateblue;

$color-divider-light: rgb(162, 157, 149);
$color-divider-dark: #344162;

$bg-footer-light: rgb(154, 146, 146);
$bg-footer-dark: rgb(70, 70, 70);

$color-footer-light: rgb(255, 255, 255);
$color-footer-dark: $secondary-dark;

$color-nav-button-light: $secondary-light;
$color-nav-button-dark: $secondary-dark;

$angled-line-width: 150px;

// Default Theme
:root {
    --scrollbar-opacity: 0.5;
    --color-background: #{$bg-dark};
    --color-primary: #{$primary-dark};
    --color-secondary: #{$secondary-dark};
    --color-link: #{$link-dark};
    --color-bg-footer: #{$secondary-dark};
    --color-footer-color: #{$bg-dark};
    --color-nav-button: #{$color-nav-button-dark};
    --color-divider: #{$color-divider-dark};
    --color-blackwhite: white;
}

// Alternate Theme
.alternate {
    --scrollbar-opacity: 0.5;
    --color-background: #{$bg-light};
    --color-primary: #{$primary-light};
    --color-secondary: #{$secondary-light};
    --color-link: #{$link-light};
    --color-bg-footer: #{$secondary-light};
    --color-footer-color: #{$bg-light};
    --color-nav-button: #{$color-nav-button-light};
    --color-divider: #{$color-divider-light};
    --color-blackwhite: black;
}

html {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
}

body {
    width: 100vw;
    min-height: 100vh;
    font-size: 16px;
    font-family: $font-primary;
    line-height: 1;
    background: var(--color-background);
    color: var(--color-primary);
    margin: 0px;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: background 0.35s ease-in-out,
        color 2.5s ease-in-out,
        opacity 1s ease-in-out;

    section {
        width: 100vw;
        height: 100vh;
        padding: 0rem;
        transition: background 3s;
        overflow-x: hidden;
    }

    p {
        font-family: $font-p;
        font-size: 1.25rem;
        line-height: 1.25;
        font-weight: lighter;
    }
    
    h1 {
        font-family: $font-h1;
        font-size: 8.5rem;
    }
    
    h2 {
        color: var(--color-secondary);
        font-size: 3rem;
    }
    
    h3 {
        font-family: $font-h3;
        font-weight: 100;
        font-size: 1.75rem;
    }

    h4 {
        font-family: $font-h4;
        font-weight: 100;
        font-size: 1.25rem;
    }

    p, h1, h2, h3, h4, h5, h6 {
        padding: 0px;
        margin: 0px;
        pointer-events: none;
        color: var(--color-primary);
    }

    header, footer {
        padding: 0px;
        margin: 0px;
    }
    
    img {
        -webkit-user-drag: none;
    }

    a {
        color: var(--color-link);
        text-decoration: none;
        margin: 0px;
        padding: 0px 1rem;
        font-size: 1.25rem;
        z-index: 10;
    }
}


@mixin divider {
    display: block;
    position: absolute;
    height: 2px;
    bottom: -15px;
    background: var(--color-divider);
}

///////
/// 
/// // from https://locomotivemtl.github.io/locomotive-scroll/

// html {
//     color: #222;
//     font-family: Akzidensk;
//     line-height: 1.5
// }

// html:not(.has-scroll-init) {
//     cursor: wait;
//     overflow: hidden
// }


@media (max-width: 699px) {
    html {
        font-size:12px
    }
}

@media (min-width: 700px) and (max-width:999px) {
    html {
        font-size:13px
    }
}

@media (min-width: 1000px) and (max-width:1199px) {
    html {
        font-size:14px
    }
}

@media (min-width: 1200px) and (max-width:1599px) {
    html {
        font-size:16px
    }
}

@media (min-width: 1600px) and (max-width:1999px) {
    html {
        font-size:18px
    }
}

@media (min-width: 2000px) and (max-width:2399px) {
    html {
        font-size:21px
    }
}

@media (min-width: 2400px) {
    html {
        font-size:24px
    }
}

main {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

hr {
    border: 0;
    border-top: 1px solid #cbcbcb;
    display: block;
    height: 1px;
    margin: 1em 0;
    padding: 0
}

.o-h,.o-h1,.o-h2,.o-h3,.o-h4,.o-h5,.o-h6,h1,h2,h3,h4,h5,h6 {
    font-weight: 400;
    line-height: 1.5;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 0
}

.o-h1,h1 {
    line-height: .9;
    text-transform: uppercase
}

@media (max-width: 699px) {
    .o-h1,h1 {
        font-size:12vw
    }
}

@media (min-width: 700px) and (max-width:1599px) {
    .o-h1,h1 {
        font-size:13.5vw
    }
}

@media (min-width: 1600px) {
    .o-h1,h1 {
        font-size:12vw
    }
}

// .o-container {
//     margin-left: auto;
//     margin-right: auto;
//     max-width: 95rem
// }

// @media (max-width: 699px) {
//     .o-container {
//         padding-left:20px;
//         padding-right: 20px
//     }
// }

// @media (min-width: 700px) {
//     .o-container {
//         padding-left:2.5rem;
//         padding-right: 2.5rem
//     }
// }

.c-header {
    position: relative
}

@media (max-width: 699px) {
    .c-header {
        height:80vh;
        min-height: 340px;
        margin: 10px;
    }
}

@media (min-width: 700px) and (max-width:999px) {
    .c-header {
        height:90vh;
        min-height: 380px;
        margin: 10px;
    }
}

@media (min-width: 1000px) {
    .c-header {
        height:100vh;
        min-height: 46.875rem
    }
}


// .c-header_line, .c-header_title {
//     //bottom: 50%;
//     left: 0;
//     margin: 0;
//     position: absolute;
//     //color: red;
// }

// .c-header_heading {
//     padding: 1.875rem 0
// }

// .c-header_heading_label {
//     display: block;
//     // transform: translateY(-60px);
//     // transition: transform 0s cubic-bezier(.215,.61,.355,1)
// }

//html.is-ready .c-header_heading_label {
    // transform: none;
    // transition-duration: .6s
//}

// html.is-ready .o-layout_item:nth-child(2) .c-header_heading_label {
//     transition-delay: .1s
// }

.o-layout {
    list-style: none;
    margin: 0;
    padding: 0
}

.o-layout_item {
    display: inline-block;
    font-size: 1rem;
    padding-left: 0;
    vertical-align: top;
    width: 100%
}

.u-label {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase
}

.u-float-left {
    float: left!important
}

.u-float-right {
    float: right!important
}

.u-text-center {
    text-align: center!important
}

.u-text-left {
    text-align: left!important
}

.u-text-right {
    text-align: right!important
}
